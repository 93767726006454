'use client';

import { ReactNode } from 'react';

import { useAuth } from '@shared/common/providers/AuthProvider';
import MixPanelProvider from '@shared/common/providers/MixPanelProvider';

const MixPanelProviderShared = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();

  return <MixPanelProvider user={user}>{children}</MixPanelProvider>;
};

export default MixPanelProviderShared;
